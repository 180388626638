<template>
  <Navbar :Msg="'Voltar'" :rota="'admin/despedidas'" />
  <div class="container">
    <v-card class="mx-auto content" max-width="800" elevation="1">
      <v-toolbar color="primary">
        <v-card-title class="headline font-weight-bold" style="padding: 16px;">
          Gerenciamento Despedida - {{ despedida.id }}
        </v-card-title>
      </v-toolbar>
      <v-card elevation="0">
        <v-tabs v-model="tab" align-tabs="center" color="#343F53">
          <v-tab :value="1">Falecido</v-tab>
          <v-tab :value="2">Moderador</v-tab>
          <v-tab :value="3">Despedida</v-tab>
        </v-tabs>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item :key="1" :value="1">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="falecido.nome" :counter="100" label="Nome" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="falecido.data_nascimento" label="Data de Nascimento" hide-details required
                      type="date" class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="falecido.data_falecimento" label="Data de Falecimento" hide-details
                      type="date" class="custom-text-field" required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="falecido.cidade" label="Cidade de Nascimento" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-model="falecido.estado" :items="states" label="Estado de Nascimento" hide-details
                      required class="custom-text-field"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-model="falecido.pais" :items="countries" label="País de Nascimento" hide-details
                      required class="custom-text-field"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <img width="160px" :src="despedida.foto_perfil" alt="Foto de Perfil" />
                    <v-file-input v-model="falecido.profilePicture" label="Foto de Perfil" hide-details accept="image/*"
                      class="custom-file-input"></v-file-input>
                  </v-col>
                  <div class="text-center pa-4">
                    <v-btn color="green" @click="updateFalecido()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>

            </v-card-text>
          </v-tabs-window-item>
          <v-tabs-window-item :key="2" :value="2">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.nome" label="Nome" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="moderador.telefone" label="Telefone do Moderador" hide-details
                      @input="maskPhone(moderador)" required class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.email" label="E-mail do Moderador" hide-details required
                      class="custom-text-field" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.password" label="Senha do Moderador" hide-details type="password"
                      required class="custom-text-field" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="moderador.moderador_cliente" label="Moderador é cliente?"
                      :items="['Sim, cliente do Grupo', 'Ainda não']" required></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="moderador.parentesco_falecido" label="Parentesco com falecido?"
                      :items="['Não informado', 'Sim', 'Não']" required></v-select>
                  </v-col>
                  <div class="text-center pa-4">
                    <v-btn color="green" @click="updateModerador()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>

            </v-card-text>
          </v-tabs-window-item>
          <v-tabs-window-item :key="3" :value="3">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="despedida.local_sepultamento" :counter="100" label="Cemitério" hide-details
                      required class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.localizacao_sepultado" :counter="100" label="Jazigo" hide-details
                      required class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.data_sepultamento" label="Data e Hora" hide-details required
                      type="date" class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.local_velorio" :counter="100" label="Velório" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.data_velorio" label="Data e Hora" hide-details required type="date"
                      class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-textarea v-model="despedida.obituario" label="Biografia" outlined counter="1000" required
                    :rules="[v => v.length <= 1000 || 'Máximo de 1000 caracteres']" rows="5"
                    placeholder="Escreva uma breve biografia..." no-resize></v-textarea>
                </v-col>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select v-model="vazio" label="Privacidade" :items="['Publica', 'Privada']" required></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="vazio" label="Comentários Públicos" :items="['Sim', 'Não']" required></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-card elevation="0">
                      <v-radio-group v-model="despedida.foto_fundo" column>
                        <v-row dense>
                          <v-col v-for="(image, index) in images" :key="index" cols="12" md="6">
                            <v-card>
                              <v-radio :value="image.id"></v-radio>
                              <img :src="require(`@/assets/pack-banners/${image.id}.jpg`)" width="400px"
                                alt="Imagem de banner" />
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-col>
                  <div class="text-center pa-4">
                    <v-btn color="green" @click="updateDespedida()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>

            </v-card-text>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-card>
  </div>
  <Footer />

  <v-dialog v-model="alert_msg" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Mensagem
      </v-card-title>
      <v-card-text>
        {{ msg_dialog }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="finalizar()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';
import { http } from '@/services/config';
import authHeader from '@/services/auth-header';

export default {
  name: 'Informacoes',
  components: {
    Navbar, Footer
  },
  data() {
    return {
      alert_msg: false,
      msg_dialog: '',
      vazio: '',
      id: null,
      tab: null,
      selectedImage: null,
      images: [
        { id: '1' },
        { id: '2' },
        { id: '3' },
        { id: '4' },
        { id: '5' },
        { id: '6' },
        { id: '7' },
        { id: '8' },
        { id: '9' },
        { id: '10' },
        { id: '11' },
        { id: '12' },
      ],
      falecido: {
      },
      moderador: {
      },
      despedida: {
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.despedidas()
  },
  methods: {
    finalizar() {
      this.alert_msg = false;
      this.$router.push('/admin/despedidas')
    },
    formatDate(date) {
      if (!date) return '';
      const isoDate = date.split('T')[0];
      const [year, month, day] = isoDate.split('-');
      return `${day}/${month}/${year}`;
    },
    despedidas() {
      http.get(`cliente/despedida/${this.id}`)
        .then(response => {
          this.despedida = response.data;
          this.falecido = this.despedida.pessoa_falecida;
          this.moderador = this.despedida.pessoa_moderador;
          console.log(this.despedida);
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
        });
    },

    async updateFalecido() {
      try {
        const response = await http.post(
          "cliente/update-pessoa-falecida",
          {
            "id": this.falecido.id,
            "nome": this.falecido.nome,
            "data_nascimento": this.falecido.data_nascimento,
            "data_falecimento": this.falecido.data_falecimento,
            "cidade": this.falecido.cidade,
            "estado": this.falecido.estado,
            "pais": this.falecido.pais
          },
          {
            headers: authHeader()
          }
        );
        this.alert_msg = true;
        this.msg_dialog = 'Atualizado com sucesso!';
        console.log("Atualizado com sucesso!" + response)
      } catch (error) {
        console.error("Erro ao atualizar falecido:", error);
        throw error;
      }
    },

    async updateModerador() {
      try {
        const response = await http.post(
          "cliente/update-pessoa-moderador",
          {
            "pessoa": {
              "id": this.moderador.id,
              "nome": this.moderador.nome,
              "telefone": this.moderador.telefone
            },
            "moderador_cliente": this.moderador.cliente,
            "parentesco_falecido": this.moderador.parentesco
          },
          {
            headers: authHeader()
          }
        );
        this.alert_msg = true;
        this.msg_dialog = 'Atualizado com sucesso!';
        console.log("Atualizado com sucesso!" + response)
      } catch (error) {
        console.error("Erro ao atualizar moderador:", error);
        throw error;
      }
    },

    async updateDespedida() {

      this.despedida.foto_perfil
      let fotoPerfilBase64 =  this.despedida.foto_perfil;

      if(this.falecido.profilePicture != null){
         fotoPerfilBase64 = await this.convertToBase64(this.falecido.profilePicture);
      }
    
      

      try {
        const response = await http.post(
          "cliente/update-despedida",
          {
            "id": this.despedida.id,
            "foto_perfil": fotoPerfilBase64,
            "funeraria": this.despedida.funeraria,
            "data_velorio": this.despedida.data_velorio,
            "local_velorio": this.despedida.local_velorio,
            "codigo_jazigo": this.despedida.codigo_jazigo,
            "data_sepultamento": this.despedida.data_sepultamento,
            "local_sepultamento": this.despedida.local_sepultamento,
            "localizacao_sepultado": this.despedida.localizacao_sepultado,
            "foto_fundo": this.despedida.foto_fundo,
            "contrato_plano": this.despedida.contrato_plano,
            "contrato_jazigo": this.despedida.contrato_jazigo,
            "dataCreate": this.despedida.dataCreate,
            "data_update": this.despedida.data_update,
            "user_create": this.despedida.user_create,
            "user_update": this.despedida.user_update,
            "status_memoria": this.despedida.status_memoria,
            "tipo": this.despedida.tipo,
            "com_moderacao": this.despedida.com_moderacao,
            "transmissao": this.despedida.transmissao,
            "codigo_transmissao": this.despedida.codigo_transmissao,
            "obituario": this.despedida.obituario
          },
          {
            headers: authHeader()
          }
        );
        this.alert_msg = true;
        this.msg_dialog = 'Atualizado com sucesso!';
        console.log("Atualizado com sucesso!" + response)
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
    },

    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file); // Converte o arquivo para Base64
      });
    },

  }
}
</script>

<style scoped>
.container {
  min-height: 78vh !important;
}

.content {
  margin: 4vh 0;
}
</style>