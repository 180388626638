<template>
  <Navbar :Msg="'Voltar'" :rota="'admin/despedidas'" />
  <v-card class="container">
    
    <v-stepper v-model="currentStep" color="red" prev-text="Voltar" :elevation="0" next-text="Próximo" :items="['Falecido', 'Moderador', 'Despedida']" ref="stepper">
      <template v-slot:item.1>
        <v-card flat>
          <v-container>
            <v-card class="mx-auto" max-width="800" min-height="58vh" elevation="1">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Falecido
                </v-card-title>
              </v-toolbar>
              <v-card-text class="title">
                <v-btn text color="indigo-darken-3" @click="dialogFalecido = true">Buscar</v-btn>
              </v-card-text>

              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="falecido.firstname" :counter="100" :rules="nameRules" label="Nome"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthdate" :rules="dateRules" label="Data de Nascimento"
                        hide-details required type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.deathdate" :rules="dateRules" label="Data de Falecimento"
                        hide-details type="date" class="custom-text-field" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="falecido.birthCity" :rules="cityRules" label="Cidade de Nascimento"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select v-model="falecido.birthState" :items="states" label="Estado de Nascimento" hide-details
                        required class="custom-text-field"></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select v-model="falecido.birthCountry" :items="countries" label="País de Nascimento"
                        hide-details required class="custom-text-field"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input v-model="falecido.profilePicture" label="Foto de Perfil" hide-details
                        accept="image/*" class="custom-file-input"></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-dialog v-model="dialogFalecido" max-width="800">
              <v-card>
                <v-card-title>Pesquisar por CPF</v-card-title>
                <v-card-text>
                  <v-card class="search d-flex align-center" elevation="0">
                    <v-text-field 
                      v-model="cpf" 
                      label="CPF" 
                      hide-details 
                      @input="maskCPF" 
                      class="flex-grow-1"
                    ></v-text-field>
                    <v-btn 
                      color="indigo-darken-2" 
                      @click="searchCPF()" 
                      class="ml-2"
                    >
                      Pesquisar
                    </v-btn>
                  </v-card>

                  <v-data-table :headers="headers" :items="cpfResults">

                    <template #item.action="{ item }">
                      <v-btn color="green" @click="selectItem(item, 'falecido')">Adicionar</v-btn>
                    </template>
                    
                    <!-- Template para customizar a coluna 'nome' -->
                    <template #item.nome="{ item }">
                      <div style="margin-top: 8px;">
                        <strong>Nome:</strong> {{ item.nome }} <br>
                        <strong>Falecido:</strong> {{ formatDate(item.falecimento) || '' }} <br>
                        <strong>Nascimento:</strong> {{ formatDate(item.nascimento) || '' }} <br>
                        <strong>Contrato:</strong> {{ item.num_contrato || '' }} <br>
                        <strong>Tipo:</strong> {{ item.tipo || 'N/A' }} <br>
                        <strong>Cemitério:</strong> {{ item.cemiterio || '' }} <br>
                        <strong>Jazigo:</strong> {{ item.localizacao || '' }}

                        
                        
                      </div>
                    </template>

  
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="dialogFalecido = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="800" elevation="2">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Moderador
                </v-card-title>
              </v-toolbar>

              <!--  <v-card-text class="title">
                <v-btn text color="indigo-darken-3" @click="dialogModerador = true">Buscar</v-btn>
              </v-card-text>-->

              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.nome" :rules="nameRules" label="Nome" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="moderador.phone" :rules="phoneRules" label="Telefone do Moderador"
                        hide-details @input="maskPhone(moderador)" required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.email" :rules="emailRules" label="E-mail do Moderador"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.password" :rules="passwordRules" label="Senha do Moderador"
                        hide-details type="password" required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="moderador.cliente" label="Moderador é cliente?"
                        :items="['Sim, cliente do Grupo', 'Ainda não']" required></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="moderador.parentesco" label="Parentesco com falecido?"
                        :items="['Não informado', 'Sim', 'Não']" required></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-dialog v-model="dialogModerador" max-width="800">
              <v-card>
                <v-card-text>
                  <v-card class="search" elevation="0">
                    <v-text-field v-model="cpf" label="CPF" hide-details @input="maskCPF"></v-text-field>
                    <v-btn color="indigo-darken-2" @click="searchCPF('moderador')">Pesquisar</v-btn>
                  </v-card>

                  <v-data-table :headers="headers" :items="cpfResults">
                    <template #item.action="{ item }">
                      <v-btn color="green" @click="selectItem(item, 'moderador')">Adicionar</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="dialogModerador = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="800" elevation="2">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Despedida
                </v-card-title>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="despedida.cemiterio" :counter="100" label="Cemitério" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.jazigo" :counter="100" label="Jazigo" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.sepHora" :rules="dateRules" label="Data e Hora" hide-details
                        required type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.velorio" :counter="100" label="Velório" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.velHora" :rules="dateRules" label="Data e Hora" hide-details
                        required type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12">
                    <v-textarea v-model="despedida.biografia" label="Biografia" outlined counter="1000" required
                      :rules="[v => v.length <= 1000 || 'Máximo de 1000 caracteres']" rows="5"
                      placeholder="Escreva uma breve biografia..." no-resize></v-textarea>
                  </v-col>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyPost" label="Privacidade" :items="['Publica', 'Privada']"
                        required></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyComments" label="Comentários Públicos" :items="['Sim', 'Não']"
                        required></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-card elevation="0">
                        <v-radio-group v-model="despedida.banner" column>
                          <v-row dense>
                            <v-col v-for="(image, index) in images" :key="index" cols="12" md="6">
                              <v-card>
                                <v-radio :value="image.id"></v-radio>
                                <img :src="require(`@/assets/pack-banners/${image.id}.jpg`)" width="400px"
                                  alt="Imagem de banner" />
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-card>
                    </v-col>
                   
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card>
        <v-dialog v-model="dialogQRCODE" width="auto" persistent>
          <v-card max-width="400" prepend-icon="mdi-update"
            :title="'Cadastro finalizado com sucesso!'" text="QRcode para acessar:">
            <div class="qrcode">
              <qrcode-vue :value="value_qrcode" :size="size" level="H" />
              <div style="margin-top: 1rem;">
                <v-btn color="green" class="ms-auto" text="Donwload" style="margin-right: 1rem;"></v-btn>
                <v-btn color="blue" class="ms-auto" text="Compartilhar"></v-btn>
              </div>
            </div>
            <template v-slot:actions>
              <v-btn class="ms-auto" text="Ok" @click="finalizarQrCode()"></v-btn>
            </template>
          </v-card>
        </v-dialog>
      </template>

    

      <template v-slot:actions="{ prev }" >
        
        <v-row class="d-flex" justify="space-between" style="height: 60px;width: 94%;margin-left: 3%">
          <v-btn text @click="prev" v-if="currentStep > 1">
            Voltar
          </v-btn>

          <span v-if="currentStep === 1"></span>
          <v-btn 
            :color="currentStep === 3 ? 'green' : 'primary'" 
            @click="handleButtonClick()"
          >
            {{ currentStep === 3 ? 'Finalizar' : 'Próximo' }}
          </v-btn>
        </v-row>
      </template>
    </v-stepper>

    <br/>


  </v-card>



</template>

<script>
import Navbar from './Navbar.vue';
import { http } from '../../services/config';
import QrcodeVue from 'qrcode.vue'
import authHeader from '@/services/auth-header';
export default {
  components: {
    QrcodeVue, Navbar
  },

  computed: {
    isLastStep() {
      return this.currentStep === this.items.length;
    },
  },

  data() {
    return {
      selectedImage: null,
      images: [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }, { id: '7' }, { id: '8' }, { id: '9' }, { id: '10' }, { id: '11' }, { id: '12' },],
      dialogQRCODE: false,
      value_qrcode: '',
      size: 150,
      currentStep: 1, // Etapa inicial
      valid: false,
      dialogFalecido: false,
      dialogModerador: false,
      cpf: '',
      cpfResults: [],
      idFalecido: 0,
      idModerador: 0,
      falecido: {
        firstname: '',
        birthdate: '',
        deathdate: '',
        birthCity: '',
        birthState: '',
        birthCountry: '',
        profilePicture: '',
      },
      moderador: {
        nome: '',
        phone: '',
        email: '',
        password: '',
        cliente: '',
        parentesco: ''
      },
      despedida: {
        cemiterio: '',
        jazigo: '',
        sepHora: '',
        velorio: '',
        velHora: '',
        biografia: '',
        banner: null,
        privacyPost: '',
        privacyComments: ''
      },
      headers: [
      { text: 'Informações', value: 'nome', title: 'Informações', sortable: false },
      { text: 'Ações', value: 'action', title: 'Ações', sortable: false }
      ],
      nameRules: [(v) => !!v || 'Nome é obrigatório'],
      dateRules: [(v) => !!v || 'Data é obrigatória'],
      cityRules: [(v) => !!v || 'Cidade é obrigatória'],
      emailRules: [
        (v) => !!v || 'E-mail é obrigatório',
        (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido'
      ],
      passwordRules: [(v) => !!v || 'Senha é obrigatória'],
      phoneRules: [(v) => !!v || 'Telefone é obrigatório'],
      states: [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA',
        'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN',
        'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'OUTRO'
      ],
      countries: ['Brasil', 'Argentina', 'Estados Unidos', 'OUTRO']
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const isoDate = date.split('T')[0];
      const [year, month, day] = isoDate.split('-');
      return `${day}/${month}/${year}`;
    },

    maskCPF() {
      this.cpf = this.applyCPFMask(this.cpf);
    },
    applyCPFMask(cpf) {
      cpf = cpf.replace(/\D/g, '');
      if (cpf.length > 11) cpf = cpf.slice(0, 11);
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    maskPhone(moderador) {
      moderador.phone = moderador.phone.replace(/\D/g, '');
      if (moderador.phone.length > 2) {
        moderador.phone = '(' + moderador.phone.slice(0, 2) + ') ' + moderador.phone.slice(2);
      }
      if (moderador.phone.length > 9) {
        moderador.phone = moderador.phone.slice(0, 10) + '-' + moderador.phone.slice(10);
      }
      if (moderador.phone.length > 15) {
        moderador.phone = moderador.phone.slice(0, 15);
      }
    },
    searchCPF() {
      this.cleanDataBeforeSearch();
      http.get("/cliente/pessoas-ivertex", {
        params: {
          cpf: this.cpf
        }
      })
        .then(response => {
          this.cpfResults = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar CPF:", error);
        });
    },
    selectItem(item, itemType) {
      if (itemType === 'falecido') {
        this.falecido.firstname = item.nome;
        this.falecido.birthdate = item.nascimento;
        this.falecido.deathdate = item.falecimento;
        this.despedida.cemiterio = item.cemiterio;
        this.despedida.jazigo = item.localizacao;
        this.despedida.sepHora = item.dtSepultamento;
        this.despedida.velorio = item.velorio;
        this.despedida.velHora = item.dtVelorio;
      } else if (itemType === 'moderador') {
        this.moderador.email = item.email || '';
        this.moderador.phone = item.telefone || '';
      }
      if (itemType === 'falecido') {
        this.dialogFalecido = false;
      } else if (itemType === 'moderador') {
        this.dialogModerador = false;
      }
    }, removeMask(value) {
      return value.replace(/\D/g, '');
    }, cleanDataBeforeSearch() {
      this.cpf = this.removeMask(this.cpf);
    },
    finalizarQrCode() {
      this.dialogQRCODE = false
      this.$router.push('despedidas')
    },
    finalizar() {
      console.log("Stepper concluído!");
      // Adicione a lógica de finalização aqui, como salvar dados ou redirecionar
    },

    handleButtonClick() {
      // Se for o último passo (step 3), chama a função finalizarCadastro
      if (this.currentStep === 3) {
        this.finalizarCadastro();
      } else {
        // Se não for o último passo, avança para o próximo
        this.$refs.stepper.next(); // Acessa o método next do v-stepper através da ref
      }
    },

    async finalizarCadastro() {
      try {
        await this.cadastrarFalecido();

        await this.cadastrarModerador();

        await this.cadastrarDespedida();

        this.dialogQRCODE = true
        console.log("Todos os cadastros foram realizados com sucesso!");
      } catch (error) {
        console.error("Erro ao realizar os cadastros:", error);
      }
    },

    async cadastrarFalecido() {
      try {
        const response = await http.post(
          "cliente/nova-pessoa-falecida",
          {
            "nome": this.falecido.firstname,
            "data_nascimento": this.falecido.birthdate,
            "data_falecimento": this.falecido.deathdate,
            "cidade": this.falecido.birthCity,
            "estado": this.falecido.birthState,
            "pais": this.falecido.birthCountry
          },
          {
            headers: authHeader()
          }
        );
        this.idFalecido = response.data.id;
        this.value_qrcode = this.falecido.firstname;
      } catch (error) {
        console.error("Erro ao cadastrar falecido:", error);
        throw error;
      }
    },

    async cadastrarModerador() {
      try {
        const response = await http.post(
          "cliente/nova-pessoa-moderador",
          {
            "username": this.moderador.email,
            "password": this.moderador.password,
            "pessoa": {
              "nome": this.moderador.nome,
              "telefone": this.moderador.phone
            },
            "moderador_cliente": this.moderador.cliente,
            "parentesco_falecido": this.moderador.parentesco
          },
          {
            headers: authHeader()
          }
        );
        this.idModerador = response.data.id;
      } catch (error) {
        console.error("Erro ao cadastrar moderador:", error);
        throw error;
      }
    },

    async cadastrarDespedida() {
      const fotoPerfilBase64 = await this.convertToBase64(this.falecido.profilePicture);
      try {
        const response = await http.post(
          "cliente/nova-despedida",
          {
            "foto_fundo": this.despedida.banner,
            "obituario": this.despedida.biografia,
            "data_sepultamento": this.despedida.sepHora,
            "local_sepultamento": this.despedida.cemiterio,
            "localizacao_sepultado": this.despedida.jazigo,
            "data_velorio": this.despedida.velHora,
            "local_velorio": this.despedida.velorio,
            "pessoa_falecida_id": this.idFalecido,
            "pessoa_moderador_id": this.idModerador,
            "foto_perfil": fotoPerfilBase64,
            "funeraria": "Funerária Exemplo Ltda",
            "status_memoria": true,
            "tipo": this.despedida.privacyPost,
            "com_moderacao": false,
            "transmissao": "https://example.com/transmissao",
            "codigo_transmissao": "TX123456",
          },
          {
            headers: authHeader()
          }
        );
        this.idDespedida = response.data.id;
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
    },
    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file); // Converte o arquivo para Base64
      });
    },
  }
};
</script>

<style scoped>
.container {
  min-height: 63vh;
}

.custom-text-field {
  margin-bottom: 1rem;
}

.custom-file-input {
  margin-top: 1rem;
}

.search {
  margin-bottom: 1rem;
}

.finish {
  margin: 1rem;
}

.qrcode {
  text-align: center;
}

.img-banner {
  width: 300px;
}
</style>
