<template>
  <section>
    <h1>Testemunhos de nossos usuários</h1>
  <div class="cards">
    <div class="card">
      <p>
        Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit, sed diam nonummy
        nibh euismod tincidunt ut
        laoreet dolore magna
        aliquam erat volutpat. Ut
        wisi enim ad minim veniam,
        quis nostrud exerci tation.
        diam nonummy nibh
        euismod tincidunt ut
        laoreet dolore magna
        aliquam erat
      </p>
      <span>
        - Maycon Henrique
      </span>
    </div>
    <div class="card">
      <p>
        Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit, sed diam nonummy
        nibh euismod tincidunt ut
        laoreet dolore magna
        aliquam erat volutpat. Ut
        wisi enim ad minim veniam,
        quis nostrud exerci tation.
        diam nonummy nibh
        euismod tincidunt ut
        laoreet dolore magna
        aliquam erat
      </p>
      <span>
        - Maycon Henrique
      </span>
    </div>
    <div class="card">
      <p>
        Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit, sed diam nonummy
        nibh euismod tincidunt ut
        laoreet dolore magna
        aliquam erat volutpat. Ut
        wisi enim ad minim veniam,
        quis nostrud exerci tation.
        diam nonummy nibh
        euismod tincidunt ut
        laoreet dolore magna
        aliquam erat
      </p>
      <span>
        - Maycon Henrique
      </span>
    </div>
  </div>
  <a href="#"><img src="../../assets/images/Elementos/depoimentos.png" alt=""></a>
  </section>
  
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  text-align: center;
  padding-top: 3rem;
  color: #343F53;
  font-size: 34px;
  font-weight: 700;
}
.cards {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 4rem 10rem;
}
.card {
  background-color: #343F53;
  border-radius: 10px;
  padding: 2rem;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cards p, span {
  color: #fff;
  font-weight: 400;
}
a img {
  padding-bottom: 1rem;
}

@media (min-width: 580px) {
  .cards {
    padding: 2rem;
  }
}
@media (max-width: 768px) {
  .cards {
    flex-direction: column;
    padding: 2rem;
  }
}
</style>