<template class="main">
  <Navbar :Msg="'Sair'" :rota="'admin/entrar'" />
  <v-container class="container">
    <v-card class="mx-auto table-pai" max-width="1000" elevation="4">
      <v-card-title class="headline font-weight-bold" style="padding: 16px;">Gerenciar Despedidas</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="serverItems" :items-per-page="itemsPerPage" :loading="loading"
          class="elevation-0 teste">
          <template #item.action="{ item }">
            <v-btn style="margin: 0.4rem 0.4rem;" @click="gerenciar(item.id)" type="submit" color="primary">
              Gerenciar
            </v-btn>
            <v-btn style="margin: 0.4rem 0.4rem;" @click="abrirDespedida(item.id)" type="submit" color="green">
              Despedida
            </v-btn>
          </template>

        </v-data-table>
        <v-col cols="12" class="text-left">
          <v-btn @click="novoPost()" type="submit" color="primary">
            Nova postagem
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { http } from '@/services/config'
import Navbar from './Navbar.vue'

export default {
  components: {
    Navbar,
  },
  data: () => ({
    itemsPerPage: 5,
    headers: [
      { title: 'Código', align: 'start', key: 'id', sortable: false },
      { title: 'Nome', align: 'start', key: 'pessoa_falecida.nome' },
      { title: 'Jazigo', align: 'start', key: 'localizacao_sepultado' },
      { title: 'Cemiterio', align: 'start', key: 'local_sepultamento' },
      { title: 'Sepultamento', align: 'start', key: 'data_sepultamento' },
      { title: 'Ação', align: 'center', key: 'action', sortable: false },
    ],
    search: '',
    serverItems: [],
    loading: true,
    totalItems: 0,
  }),
  mounted() {
    this.despedidas()
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const isoDate = date.split('T')[0];
      const [year, month, day] = isoDate.split('-');
      return `${day}/${month}/${year}`;
    },
    despedidas() {
      this.loading = true;
      http.get("cliente/despedidas")
        .then(response => {
          // Formata os campos de data
          this.serverItems = response.data.map(item => ({
            ...item,
            data_sepultamento: this.formatDate(item.data_sepultamento)
          }));

          this.totalItems = response.data.length;
          this.loading = false;
          console.log(this.serverItems);
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
          this.loading = false;
        });
    },
    novoPost() {
      this.$router.push('/admin/criar');
    },
    gerenciar(cod) {
      this.$router.push('/admin/informacoes/' + cod);
    },
    abrirDespedida(cod) {
      this.$router.push(`/post/${cod}`);
    }
  },
}
</script>

<style scoped>
.container {
  min-height: 78vh;
}

.table-pai {
  margin-top: 4vh;
}
</style>
