import { createStore } from 'vuex'

import { auth } from './store/auth.module'

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  barColor: '',
  barImage: '',
  drawer: null,
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
}

export default createStore({
  state,
  mutations,
  modules: {
    auth,
  },
})
