<script >
import { defineComponent } from 'vue';
import menuIconOpen from '@/assets/images/icons/close_white_36dp.svg';
import menuIconClosed from '@/assets/images/icons/menu_white_36dp.svg';

export default defineComponent({
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    menuIcon() {
      return this.isMenuOpen ? menuIconOpen : menuIconClosed;
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  }
});
</script>

<template>
  <nav>
    <div class="logomain">
      <router-link to="/"><img src="../../assets/images/logos/GRUPOPARQUE.png" alt=""></router-link>
    </div>
    <ul class="nav-list">
      <router-link to="/"><li><a href="#">Home</a></li></router-link>
    </ul>

    <div class="mobile-menu-icon">
      <button @click="toggleMenu">
        <img class="icon" :src="menuIcon" alt="">
      </button>
    </div>
    <ul class="mobile-menu" :class="{ open: isMenuOpen }">
      <router-link to="/"><li><a href="#">Home</a></li></router-link>
    </ul>
  </nav>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
  font-size: 16px;
}

.nav-list a:hover {
  opacity: 0.5;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #343F53;
  min-height: 8vh;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-list li {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logomain {
  margin: 0.8rem;
  width: 150px;
  height: 36px;
}

.logomain img {
  width: 100%;
  height: 100%;
}
.mobile-menu-icon {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 5rem; 
  left: 0;
  width: 100%;
  background-color: #343F53;
  z-index: 1000;
  text-align: center;
}

.mobile-menu li {
  padding-top: 1.2rem;
}

.mobile-menu.open {
  display: block;
}

@media screen and (max-width: 815px) {

  nav {
    padding: 1.5rem 4rem;
  }

  .nav-list li {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .mobile-menu-icon button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .mobile-menu {
    display: none;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-top: 1.5rem;
    list-style: none;
    
  }
  .mobile-menu li {
    display: flex;
    flex-direction: column;
    gap: 12rem;
  }
  .mobile-menu.open {
    display: block;
  }
}
</style>
