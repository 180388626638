<template>
  <section>
    <div>
      <h1 class="frase">
        O Parque das Recordações é
        um memorial virtual, criado
        para homenagear e conectar
        pessoas. Crie um espaço
        dedicado às memórias de
        pessoas queridas e compartilhe
        com amigos e familiares.
      </h1>
    </div>
    <div class="imagem">
      <img src="../../assets/images/Elementos/casal.png" alt="">
    </div>
  </section>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}

section {
  background: url('../../assets/images/Elementos/fundo-section2.png');
  background-size: cover ;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.frase {
  color: #FFFFFF;
  padding-left: 15rem;
  font-size: 37px;
  font-weight: 600;
}

.imagem img {
  padding-top: 2rem;
}

@media (max-width: 1150px) {
  section {
    flex-direction: column;
  }
  .frase {
    padding: 0 1rem;
    padding-top: 4rem;
    text-align: center;
    font-size: 25px;
  }
  .imagem {
    display: flex;
    justify-content: center;
  }
  .imagem img {
    width: 80%;
    
  }
}
</style>