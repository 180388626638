import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

app.config.warnComponentName = false;
app.use(vuetify).use(store).use(router).mount("#app");