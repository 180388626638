<script >
import Navbar from '@/components/home/Navbar.vue'
import Footer from '@/components/home/Footer.vue'
import Main from '@/components/home/Main.vue'
export default {
  components: { Navbar, Footer, Main}
}
</script>

<template>
  <Navbar />
  <Main />
  <Footer />
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
</style>
