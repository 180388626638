<template>
  <footer>
    <div class="container">
      <p>
        O Grupo Parque das Flores surgiu em 1973 e atua há 50 anos
        no segmento do luto em Alagoas. Oferecendo respeito,
        dignidade, conforto e amparo às famílias do nosso estado.
      </p>
      <hr>
      <h1>Conheça as nossas empresas:</h1>
      <img src="../../assets/images/Elementos/logosempresas.png" alt="">
    </div>
    <div class="subcontainer">
      <div class="links">
        <a href="" class="link">Brasil</a>
        <a href="" class="link">Política de Privacidade</a>
        <a href="" class="link">Termos de Uso</a>
        <a href="" class="end">
          2024 Grupo Parque das Flores. Todos os direitos reservados.
          R. Des. Barreto Cardoso, 317 - Gruta de Lourdes, Maceió - AL,
          57052-850
        </a>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
footer .container {
  background-color: #343F53;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  gap: 2rem;
}

footer .subcontainer {
  background-color: #FFFFFF;
}

p {
  color: #FFFFFF;
  font-size: 25px;
  font-weight: 100;
  text-align: center;
  padding: 0 20rem;
  margin-top: 1.5rem;
}

h1 {
  color: #FFFFFF;
  font-size: 49px;
  font-weight: 600;
  font-family: "Sora", sans-serif;
}

hr {
  min-width: 25rem;
}

a {
  text-decoration: none;
  color: #343F53;
  font-size: 18px;
  font-weight: 500;
  font-family: "Sora", sans-serif;
}

.links{
  display: flex;
  flex-direction: row;
  gap: 8rem;
  padding: 1rem 3rem;
  justify-content: space-around;
}
.link {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 1024px) {
  p {
    padding: 0 2rem;
  }
  h1 {
    font-size: 30px;
  }
  img {
    width: 80%;
  }
  .links {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  .links a {
    display: block;
    text-align: center;
  }
  .container h1 {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  p {
    padding: 0 2rem;
  }
  h1 {
    font-size: 30px;
  }
  img {
    width: 80%;
  }
  .links {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  .links a {
    display: block;
    text-align: center;
  }
  .container h1 {
    padding: 1rem;
  }
}
</style>
