<script>
import Footer from './Footer.vue'
import Form from './Form.vue';
export default {
    components: { Footer, Form }
  }
</script>

<template>
  <Form />
  <Footer />
</template>