<script >
import Footer from '@/components/register/Footer.vue'
import PostList from '@/components/register/PostList.vue';
export default {
components: {  Footer, PostList }
}
</script>

<template>
  <PostList />
  <Footer />
</template>