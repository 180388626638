<script >
  import Login from './Login.vue'
  import Sobre from './Sobre.vue'
  import Informacoes from './Informacoes.vue'
  import Memoriais from './Memoriais.vue';
  import Testemunhos from './Testemunhos.vue'
  export default {
    components: { Login, Sobre, Informacoes, Memoriais, Testemunhos }
  }
</script>

<template>
  <Login />
  <Sobre />
  <Informacoes />
  <Memoriais />
  <Testemunhos />
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
</style>