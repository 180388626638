import { createRouter, createWebHistory } from "vue-router";

// Rotas
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import Register from "../views/Register.vue";
import Create from "../components/register/Create.vue";
import Postagem from "@/components/homenagem/Postagem.vue";
import Informacoes from "../components/register/Informacoes.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/post/:id", name: "Postagem", component: Postagem },
  { path: "/admin/entrar", component: Admin },
  { path: "/admin/despedidas", component: Register },
  { path: "/admin/criar", component: Create },
  { path: "/admin/informacoes/:id", name: "Informacoes", component: Informacoes },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
