<script>
import Navbar from '@/components/home/Navbar.vue'
import Footer from '@/components/register/Footer.vue'
import { http } from '@/services/config';
import dayjs from 'dayjs';
import authHeader from '@/services/auth-header';

export default {
  components: { Navbar, Footer },
  mounted() {
    this.id = this.$route.params.id;
    this.despedidas()
    this.consultarMensagens()
  },
  methods: {
    fechadDilog(){
      window.location.reload();
      this.tab = '2';
    },
    convertToEmbed(url) {
      if (!url) return '';
      // eslint-disable-next-line no-useless-escape
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^&?\/]+)/;
      const match = url.match(regex);
      const videoId = match ? (match[1] || match[2]) : null;
      return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    despedidas() {
      http.get(`cliente/despedida/${this.id}`)
        .then(response => {
          this.despedida = response.data;
          this.falecido = this.despedida.pessoa_falecida;
          this.moderador = this.despedida.pessoa_moderador;
          console.log(this.despedida);
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
        });
    },
    consultarMensagens() {
      http.get("cliente/homenagem/" + this.id)
        .then(response => {
          this.posts = response.data
        }
        ).catch(error => {
          console.error("Erro ao buscar CPF:", error);
        });
    },
    async newMensagem() {
      this.mensagem = false;

      try {
        const fotoPerfilBase64 = await this.convertToBase64(this.post_new.img);

        // Faz a requisição
        const response = await http.post("cliente/homenagem", {
          descricao: this.post_new.msg,
          nome: this.post_new.autor,
          despedida: this.id,
          imagem: fotoPerfilBase64,
          url: this.post_new.url,
          publicado: true,
        }, {
          headers: authHeader(),
        });

        this.alert_msg = true;
        this.msg_dialog = 'Mensagem enviada com sucesso!';
        console.log(response)
      } catch (error) {
        console.error("Erro ao enviar mensagem:", error);
        this.msg_dialog = 'Erro ao enviar mensagem!';
      }
    },
    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file || !(file instanceof Blob)) {
          resolve(''); // Retorna uma string vazia se o arquivo for inválido
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file); // Converte o arquivo para Base64
      });
    },

  },
  data: () => ({
    alert_msg: false,
    msg_dialog: '',
    mensagem: false,
    posts: [],
    post_new: {
      img: '',
      msg: '',
      autor: '',
      url: ''
    },
    id: null,
    falecido: {
    },
    moderador: {
    },
    despedida: {
      foto_fundo: 1
    },
    tab: "1",
    items: [
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Maycon Henrique",
        data: '15/08/2024',
        image: 'https://images.unsplash.com/photo-1456659122552-6ee1788174bb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Rostand Henrique",
        data: '15/08/2023'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Victor Henrique",
        data: '15/08/2022'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Nelson Henrique",
        data: '15/08/2021',
        image: 'https://images.unsplash.com/photo-1494859780588-cf6063220793?q=80&w=1985&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
    ],
    aovivo: {
      link: 'Na0w3Mz46GA'
    },
  }),
}
</script>


<template>
  <Navbar />
  <section class="banner"
    :style="{ backgroundImage: `url(${require(`@/assets/pack-banners/${despedida.foto_fundo}.jpg`)})` }">
    <div class="imgperfil">
      <img v-if="!despedida.foto_perfil" src="../../assets/images/Elementos/branco.png" alt="">
      <img v-else :src="despedida.foto_perfil" alt="Foto de Perfil" />
    </div>
  </section>
  <section class="dados">
    <h1>{{ falecido.nome }}</h1>
    <div class="date">
      <div class="star">
        <img src="@/assets/images/icons/star.png" alt="Star icon">
      </div>
      <h2>{{ formatDate(falecido.data_nascimento) }}</h2>
      <div class="cross">
        <img src="@/assets/images/icons/cross.png" alt="Cross icon">
      </div>
      <h2>{{ formatDate(falecido.data_falecimento) }}</h2>
    </div>
  </section>
  <v-card elevation="0">
    <v-tabs v-model="tab" align-tabs="center" background-color="white" color="#343F53" slider-color="#343F53" grow>
      <v-tab value="1" class="button biografia">
        <img src="@/assets/images/icons/book.png" alt="" class="tab-icon">
        <p class="tab">Biografia</p>
      </v-tab>
      <v-tab value="2" class="button mensagens">
        <img src="@/assets/images/icons/chat.png" alt="" class="tab-icon">
        <p class="tab">Mensagens</p>
      </v-tab>
      <v-tab value="3" class="button velorio">
        <img src="@/assets/images/icons/virtual.png" alt="" class="tab-icon">
        <p class="tab">Velório virtual</p>
      </v-tab>
      <v-tab value="4" class="button servicos">
        <img src="@/assets/images/icons/servicos.png" alt="" class="tab-icon">
        <p class="tab">Serviços</p>
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="1">
          <v-container fluid>
            <v-row>
              <v-col>
                <h2 class="title">Biografia</h2>
                <p class="msgobtuario">{{ despedida.obituario }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>

        <v-tabs-window-item value="2">
          <v-container fluid>
            <v-row>
              <v-col>
                <h2 class="title">Mensagens</h2>
                <br>
                <v-btn @click="mensagem = true" color="#343F53">
                  Adicionar
                </v-btn>

                <v-timeline justify="center" align="start" style="margin-bottom: 26rem;">
                  <v-timeline-item v-for="(item, i) in posts" :key="i" :dot-color="item.color" fill-dot align="center">
                    <v-card class="timeline-card">
                      <div v-if="item.imagem != null" class="timeline-image">
                        <img :src="item.imagem" alt="" class="image">
                      </div>
                      <div v-else-if="item.url" class="timeline-image">
                        <iframe width="355" height="200" :src="convertToEmbed(item.url)" title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen>
                        </iframe>
                      </div>

                      <div v-if="item.imagem || item.url" class="timeline-divider"></div>
                      <v-card-text class="timeline-content">
                        <v-card-title class="timeline-title">
                          {{ item.title }}
                        </v-card-title>

                        <v-card-text class="timeline-text">
                          {{ item.descricao }}
                          <v-card-text v-if="item.nome && item.data_create">
                            {{ item.nome }} - {{ formatDate(item.data_create) }}
                          </v-card-text>
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>

        <v-tabs-window-item value="3">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" md="8">
                <h2 class="title">Velório Virtual</h2>
                <v-card class="video-card" elevation="2">
                  <v-responsive :aspect-ratio="16 / 9">
                    <iframe :src="'https://veloriovirtual.grupoparque.com.br/'" frameborder="0" allowfullscreen
                      class="video-frame" style="width: 100%; height: 100%;"></iframe>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>


        <v-tabs-window-item value="4">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="8">
                <h2 class="title">Serviços</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Velório</h3>
                <p class="info">
                  <v-icon left>
                    <img src="@/assets/images/icons/location.svg" alt="">
                  </v-icon> {{ despedida.local_velorio }} - {{ formatDate(despedida.data_velorio) }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Sepultamento</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon>
                  {{ despedida.local_sepultamento }}
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/info.svg" alt=""></v-icon> Jazigo: {{
                    despedida.localizacao_sepultado }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">A Flor do Café - Floricultura - Campo Santo Parque das Flores</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> Campo Santo Parque das
                  Flores, Maceió - AL
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/book.svg" alt=""></v-icon> Catálogo:
                  <a href="https://catalogo.aflordocafe.com.br" target="_blank">
                    https://catalogo.aflordocafe.com.br
                  </a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/phone.svg" alt=""></v-icon> Telefone:
                  <a href="tel:+558233715258">(82) 3371-5258</a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/whatsapp.svg" alt=""></v-icon> WhatsApp:
                  <a href="tel:+5582999692001">(82) 99969-2001</a>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Memorial Flores - Floricultura - Memorial Parque Maceió</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> Cemitério Memorial
                  Parque Maceió, Maceió - AL
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/book.svg" alt=""></v-icon> Catálogo:
                  <a href="https://memorialflores.com.br" target="_blank">
                    https://memorialflores.com.br
                  </a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/phone.svg" alt=""></v-icon> Telefone:
                  <a href="tel:+558233715258">(82) 3371-5258</a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/whatsapp.svg" alt=""></v-icon> WhatsApp:
                  <a href="tel:+5582999692001">(82) 99969-2001</a>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
  <Footer />


  <v-dialog v-model="mensagem" persistent>
    <v-card style="max-width: 500px; width: 100%;">
      <v-card-title class="text-h5 grey lighten-2">
        Digite uma nova Mensagem
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-textarea v-model="post_new.msg" label="Biografia" outlined counter="1000" required
            :rules="[v => v.length <= 1000 || 'Máximo de 1000 caracteres']" rows="5"
            placeholder="Escreva uma breve biografia..." no-resize></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="post_new.autor" :counter="100" label="Autor" hide-details required
            class="custom-text-field">
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select v-model="post_new.tipo" :items="['Imagem', 'URL']" label="Escolha o tipo de anexo" outlined
            required></v-select>
        </v-col>
        <v-col cols="12" v-if="post_new.tipo === 'Imagem'">
          <v-file-input v-model="post_new.img" label="Foto de anexo" hide-details accept="image/*"
            class="custom-file-input"></v-file-input>
        </v-col>
        <v-col cols="12" v-if="post_new.tipo === 'URL'">
          <v-text-field v-model="post_new.url" label="Insira a URL" hide-details outlined required>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="mensagem = false">
          Cancelar
        </v-btn>
        <v-btn color="primary" text @click="newMensagem()">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="alert_msg" max-width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Mensagem
      </v-card-title>
      <v-card-text>
        {{ msg_dialog }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fechadDilog()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
*,
*::before,
*::after {
  font-family: "Sora", sans-serif;
}


.banner {
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.banner div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner div img {
  border-radius: 100%;
  width: 260px;
  height: 260px;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.dados {
  margin-top: 8rem;
}

.dados h1 {
  text-align: center;
  color: #343F53;
  font-weight: 900;
  font-size: 50px;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.star,
.cross {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star img,
.cross img {
  width: 20px;
}

.cross {
  margin-left: 1rem;
}

.date h2 {
  text-align: center;
  color: #343F53;
  font-weight: 300;
  font-size: 20px;
}

.v-card {
  margin: 2rem auto;
  max-width: 1000px;
  min-height: 30vh;
}

.tab-icon {
  width: 22px;
  margin-right: 0.5rem;
}

.title {
  color: #343F53;
  font-size: 2.6rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-bottom: 0.1rem;
}

.title::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #343F53;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tab {
  color: #343F53;
  font-size: 1.2rem;
  letter-spacing: 0;
}

.msgobtuario {
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343F53;
  font-size: 1.2rem;
}

.v-tabs .v-tab {
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0 2rem;
  margin-left: 0.4rem;
  border: 1px solid #343F53;
}

.button:hover {
  background-color: #343F53;
}

.button:hover p {
  color: #fff;
}

.biografia:hover img {
  content: url("@/assets/images/icons/book_white.png");
}

.mensagens:hover img {
  content: url("@/assets/images/icons/chat2_white.svg");
}

.velorio:hover img {
  content: url("@/assets/images/icons/virtual_white.png");
}

.servicos:hover img {
  content: url("@/assets/images/icons/servicos_white.png");
}

.timeline-card {
  border: 1px solid #343F53;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 100%;
}

.timeline-image {
  padding: 1rem;
}

.timeline-image .image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.timeline-divider {
  height: 1px;
  background-color: #343F53;
}

.timeline-content {
  padding: 1rem;
}

.timeline-title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.timeline-text {
  color: #343F53;
}

.video-card {
  border-radius: 12px;
  overflow: hidden;
}

.video-frame {
  border-radius: 12px;
}

.subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #343F53;
}

.info {
  font-size: 1rem;
  font-weight: 400;
  color: #343F53;
  margin-bottom: 0.4rem;
}

a {
  color: #343F53;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
