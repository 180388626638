<template>

  <section>
    <h1>Memoriais abertos e publicados recentemente</h1>
    <div class="cards">
      <div class="card">
        <a href=""><img src="@/assets/images/Elementos/memorial.png" alt="">
        <h2>
          David Cadastro
        </h2>
        <h3>
          1951-2024
        </h3></a>
      </div>
      <div class="card">
        <a href=""><img src="@/assets/images/Elementos/memorial.png" alt="">
        <h2>
          David Cadastro
        </h2>
        <h3>
          1951-2024
        </h3></a>
      </div>
      <div class="card">
        <a href=""><img src="@/assets/images/Elementos/memorial.png" alt="">
        <h2>
          David Cadastro
        </h2>
        <h3>
          1951-2024
        </h3></a>
      </div>
      <div class="card">
        <a href=""><img src="@/assets/images/Elementos/memorial.png" alt="">
        <h2>
          David Cadastro
        </h2>
        <h3>
          1951-2024
        </h3></a>
      </div>

    </div>
  </section>

</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
section {
  background-color: #343F53;
}
h1 {
  font-size: 34px;
  text-align: center;
  padding: 3rem 0rem;
  color: #ffff;
}
a {
  text-decoration: none;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.card {
  flex: 1 1 calc(25% -20px);
  padding-bottom: 2rem;
}

.card h2, h3 {
  text-align: center;
  color: #ffff;
}
@media (max-width: 768px) {
    .card {
        flex: 1 1 calc(50% - 20px);
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 480px) {
    .card {
        flex: 1 1 calc(100% - 20px);
    }
}
</style>