<script >
import Login from '@/components/adm/Login.vue'
import Footer from '@/components/home/Footer.vue'
import Navbar from '@/components/adm/Navbar.vue';
export default {
  name: 'Admin',
  components: { Login, Footer, Navbar }
}
</script>

<template>
  <Navbar />
  <Login />
  <Footer />
</template>

<style scoped>

</style>
